<template>
  <div class="home">
    <img src="~img/31.png" class="logo">
    <header>
      <ul>
        <li @click='$router.push({ path: "/desksidea" })'>
          <img src="~img/30.gif" class="cloes">
          <!-- <img src="~img/22.png"> -->
          <p>SHOP<br>ALL</p>
        </li>
        <li @click='$router.push({ path: "/side" })'>
          <img src="~img/puzzle.gif" class="bomb">
          <!-- <img src="~img/25.png"> -->
          <p>BE OUR<br />PUZZ-BUD</p>
          <!-- <div class="count-down">
            <span><span v-show="hours < 10">0</span>{{hours}}</span>
            <span>:</span>
            <span><span v-show="minutes < 10">0</span>{{minutes}}</span>
            <span>:</span>
            <span><span v-show="seconds < 10">0</span>{{seconds}}</span>
          </div> -->
        </li>
        <li @click='$router.push({ path: "/project" })'>
          <img src="~img/cup.gif" class="Writing-board">
          <!-- <img src="~img/23.png" class="tow-img"> -->
          <p>OUR<br />STORIES</p>
        </li>
        <li @click='$router.push({ path: "/deskabout" })'>
          <!-- <img src="~img/24.png" class="three-img"> -->
          <p>FIND<br>US</p>
          <img src="~img/questionmark.gif" class="ask">
        </li>

      </ul>
    </header>

    <horserace :color='noticeColor' :background='background' />
  </div>
</template>



<script>
import panav from "@/components/pc/nav.vue";
import horserace from "@/components/pc/horserace.vue";
export default {
  components: {
    panav,
    horserace,
  },
  data() {
    return {
      color: "#4aaeff",
      showOpen: false,
      showCloes: true,
      noticeColor: "#fff",
      background: "#ff7030",
      t: null,
      completeTime: 0, // 目标时间
      time: "",
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  created() {
    this.getTiem();
  },
  mounted() {},
  methods: {
    async getTiem() {
      const res = await this.axios.get("/api/index/time");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        console.log(data);
        this.completeTime = data.times;
        this.interval(this.completeTime);
      }
    },
    mouseOver() {
      this.showOpen = true;
      this.showCloes = false;
    },
    mouseLeave() {
      this.showOpen = false;
      this.showCloes = true;
    },
    interval(completeTime) {
      var stime = Date.parse(new Date()); // 当前时间
      var etime = Date.parse(new Date(completeTime));
      var usedTime = etime - stime; //两个时间戳相差的毫秒数
      if (usedTime < 0) return;
      this.days = Math.floor(usedTime / (24 * 3600 * 1000));
      //计算出小时数
      let leave1 = usedTime % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      this.hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      this.minutes = Math.floor(leave2 / (60 * 1000));
      let leave3 = leave2 % (60 * 1000);
      this.seconds = Math.round(leave3 / 1000);
      this.time = setTimeout(() => {
        this.interval(this.completeTime);
      }, 1000);
    },
  },
  destroyed() {
    clearTimeout(this.time); // 清除
  },
};
</script>


<style lang="less" scoped>
.home {
  background: #ffd7d6;
  position: relative;
  padding-top: 26px;
  .logo {
    width: 51.47%;
    margin: auto;
    display: block;
    margin-bottom: 59px;
    position: sticky;
    top: 26px;
    z-index: 999;
  }
  header {
    margin-bottom: 20px;
    ul {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      li {
        height: 100%;
        padding-top: 22px;
        cursor: pointer;
        margin-bottom: 220px;
        position: relative;
        // background: orange;
        // border: 1px solid #ccc;
        width: 100%;
        img {
          width: 60%;
          margin: 0 auto;
          display: block;
          position: relative;
        }
        p {
          text-align: center;
          font-size: 60px;
          font-family: PHANBO;
          color: coral;
          -webkit-text-stroke: 2px #000;
          // z-index: 999;
          position: relative;
          line-height: 72px;
          text-align: center;
        }
        .cloes {
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          top: 40%;
        }
        .Writing-board {
          position: absolute;
          left: 50%;
          top: 180%;
          transform: translate(-50%, -50%);

          width: 100%;
        }
        // .ask {
        //   position: absolute;
        //   left: 50%;
        //   transform: translate(-50%);
        //   top: 90%;
        // }
        .three-img {
          width: 50%;
        }
        .count-down {
          width: 120px;
          height: 32px;
          background: rgb(199, 197, 197);

          border-radius: 5px;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          font-family: DIGItAL;
          color: #000;
          position: absolute;
          right: 22px;
          bottom: -80%;
        }
        .bomb {
          width: 80%;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          top: 50%;
        }
      }
      li:nth-child(2) {
        p {
          color: #9f9279;
        }
      }
      li:nth-child(3) {
        margin-bottom: 380px;
        p {
          color: #b7d3f3;
        }
      }
      li:nth-child(4) {
        margin-bottom: 93px;
        p {
          color: #419065;
        }
      }
    }
  }
}
</style>

